import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";


const StyledWrapper = styled.div`
  .external-links {
    background-color: ${(props) => props.theme["upReachSmnColor"]};
    text-align: center;
  }

  .header-external-links {
    margin-top: ${(props) => props.theme["pixel-20"]};
    font-size: 12px;
    color: ${(props) => props.theme["externalLinkColor"]};
    font-weight: normal;
    text-transform: uppercase;
  }

  .transform-sm {
    text-transform: unset;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme["primaryColor"]} !important;
  }

  .upreach {
    color: ${(props) => props.theme["secondaryColor"]};
    font-size: ${(props) => props.theme["upreachNameHeader"]};
    font-weight: ${(props) => props.theme["footerLinkBold"]};
  }

  .grid-upper-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto auto auto auto auto;
  }

  .grid-lower-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto auto auto auto auto auto auto;
  }

  .internal-links {
    display: block;
    text-transform: uppercase;
    font-size: ${(props) => props.theme["headerLinkSize"]};
    font-weight: ${(props) => props.theme["headingBold"]};
  }

  .donate {
    color: ${(props) => props.theme["borderBottomColor"]};
  }

  .internal-links-a-tag {
    color: ${(props) => props.theme["primaryColor"]};
    position: relative;
    top: 5px;
    width: fit-content;
    margin: auto;
  }

  .internal-links-a-tag:hover {
    border-bottom: 2px solid #464FD0;
    text-decoration:none;
  }

  .active {
    border-bottom: 2px solid #464FD0;
  }

  .header-mobile-desktop-visibility {
    @media (max-width: 992px) {
      display: none;
    }
  }
  
  .border-on-hover,.border-on-hover:active,.border-on-hover:hover {
    text-decoration:none;
    color:#000000;
  }
  
  .border-on-hover:hover {  
    text-decoration:none;
    border: #2A456 bottom solid;
  }

  .sticky-nav {
    background-color: white;
    width: 100%;
    z-index: 9;
  }

  .upreach-transforming {
    position: relative;
    top: 15px;
  }

  .upreach-program {
    position: relative;
    left: -5px;
  }

  .lower-grid{
    display:flex;
    justify-content: space-around;
  }
`;

const Header = () => {
  const [fixed, setFixed] = React.useState(80);
  const [position, setPosition] = React.useState(null);

  const pixelValue = fixed + "px";

  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset > 80) {
        setFixed(0);
        setPosition("fixed");
      }
      if (window.pageYOffset < 80) {
        setFixed(80);
        setPosition("unset");
      }
    });
  }, []);

  return (
    <StyledWrapper>
      <div className="header-mobile-desktop-visibility">
        <div className="grid-upper-container pb-3 external-links">
          <div className="item1">
            <div className="upreach-transforming">
            <p className="header-external-links upreach upreach-program my-0">Social Mobility Network</p>
            </div>
          </div>
          <div className="item2 white-background">
            <Link target="_blank" to="https://upreach.org.uk">
              <p className="header-external-links border-right px-3">
                upReach
              </p>
            </Link>
          </div>
          <div className="item3 white-background">
            <Link target="_blank" to="https://aspire.upreach.org.uk">
              <p className="header-external-links border-right px-3">
                SIXTH-FORM
              </p>
            </Link>
          </div>
          <div className="item4 white-background">
            <Link target="_blank" to="https://realrating.co.uk">
              <p className="header-external-links border-right px-3">
                Contextualised Recruitment
              </p>
            </Link>
          </div>
          <div className="item5 white-background">
            <Link target="_blank" to="https://getemployable.org">
              <p className="header-external-links border-right px-3">
                Graduate Employability Framework
              </p>
            </Link>
          </div>
          <div className="item6 white-background">
            <Link target="_blank" to="https://studentsocialmobilityawards.org.uk">
              <p className="header-external-links px-3">
                STUDENT SOCIAL MOBILITY AWARDS
              </p>
            </Link>
          </div>
        </div>
        <div className="sticky-nav" style={{top:pixelValue,position:position}}>
          <div className="lower-grid py-4">
            <div className="item2 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#about">
                <span className="border-on-hover">About </span>
              </AnchorLink>
            </div>
            <div className="item3 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#partners">
                <span className="border-on-hover">Partners </span>
              </AnchorLink>
            </div>
            <div className="item4 internal-links">
              <Link activeClassName="active" className="internal-links-a-tag" to="https://upreach.org.uk/" target="_blank" rel="nofollow noreferrer">
                <span className="border-on-hover">Upreach </span>
              </Link>
            </div>
            <div className="item3 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#applynow">
                <span className="border-on-hover">Apply Now </span>
              </AnchorLink>
            </div>
            <div className="item5 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#login">
                <span className="border-on-hover">Login </span>
              </AnchorLink>
            </div>
            <div className="item6 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#donate" target="_blank" rel="noreferrer nofollow">
                <span className="border-on-hover">Donate </span>
              </AnchorLink>
            </div>
            <div className="item7 internal-links">
              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#contact">
                <span className="border-on-hover">Contact </span>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;
