import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import tweet from "../images/tweet-black.svg";
import fb from "../images/fb-black.svg";
import ln from "../images/ln-black.svg";

const StyledWrapper = styled.div`
  .left-sidebar {
    left: ${(props) => props.theme["zero"]};
    overflow: scroll;
  
    @media (max-width: 600px) {
      top: 80px;
    }
    @media (min-width: 600px) and (max-width: 991px) {
      top: 90px;
    }
  }

  .header-link-divider {
    border-bottom: 1px solid ${(props) => props.theme["leftSidebarLineDivider"]};
    max-width: 250px;
    margin-left: ${(props) => props.theme["pixel-50"]};
  }

  .sidebar-internal-links, .sidebar-external-links {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 50px;
  }

  .sm-black {
    width: 35px;
    height: 35px;
  }

  .sm-all-icons {
    margin-left: 25px; 
  }

`;

const SidebarLeft = [
  {
    topLinks: [
      { navLink: "ABOUT", href:"/#about", color: "black", isAnchor: true },
      { navLink: "PARTNERS", href:"/#partners", color: "black", isAnchor: true },
      { navLink: "UPREACH", href:"https://upreach.org.uk/", color: "black" },
      { navLink: "APPLYNOW", href:"/#applynow", color: "black", isAnchor: true },
      { navLink: "LOGIN", href:"/#login", color: "black", isAnchor: true },
      { navLink: "DONATE", href:"/#donate", color: "black", isAnchor: true },
      { navLink: "CONTACT", href:"/#contact", color: "black", isAnchor: true },
    ],
  },
  {
    BottomLinks: [
      { navLink: "Latest", href:"/latest" },
      { navLink: "Press Releases", href:"/press-releases" },
      { navLink: "News", href:"/news" },
      { navLink: "Work For Us", href:"/work-for-us" },
      { navLink: "Vacancies", href:"/vacancies" },
    ],
  },
];

const LeftSidebar = ({ setLeftSidebar, leftSidebar, setRightSidebar, rightSidebar }) => {

    return (
    <StyledWrapper>
      <nav className="container-fluid left-sidebar sidebar">
        <div className="row">
          <div className="col-3">
            {/* <div
              onClick={() => setLeftSidebar(!leftSidebar)}
              className={leftSidebar ? "open" : "d-block"}
              id="menu-toggle"
            >
              <button
                className={
                  leftSidebar
                    ? "hamburger hamburger--squeeze is-active"
                    : "hamburger hamburger--squeeze"
                }
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div> */}
          </div>
        </div>
        <div className="row px-4 mt-5">
          {SidebarLeft[0].topLinks.map((link) => (
            <div className="col-12 last-child-donate">
              { link.isAnchor ?
                  <AnchorLink className="sidebar-internal-links" to={link.href} onAnchorLinkClick={() => setLeftSidebar(!leftSidebar)}>
                    <p style={{ color: link.color }}>{link.navLink}</p>
                  </AnchorLink>
                  :
                  <Link className="sidebar-internal-links" to={link.href}>
                    <p style={{ color: link.color }}>{link.navLink}</p>
                  </Link>
              }

            </div>
          ))}
        </div>
        <div className="row mt-5 sm-all-icons">
          <Link to="">
          <img className="sm-black" src={tweet} alt=""/>
          </Link>
          <Link to="">
          <img className="sm-black mx-3" src={fb} alt=""/>
          </Link>
          <Link to="">
          <img className="sm-black" src={ln} alt=""/>
          </Link>
        </div>
      </nav>
    </StyledWrapper>
  );
};

export default LeftSidebar;
