import React from "react"
import styled from "styled-components"
import tweet from "../images/SMLogos/7.svg"
import fb from "../images/SMLogos/8.svg"
import ln from "../images/SMLogos/9.svg"
import ig from "../images/SMLogos/10.svg"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  color: ${props => props.theme["secondaryColor"]};
  // margin-top: ${props => props.theme["pixel-100"]};
  background-color: ${props => props.theme["upReachSmnColor"]};

  .footer-links {
    font-size: ${props => props.theme["FooterLinkSize"]};
    line-height: ${props => props.theme["footerLinkLineHeight"]};
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .footer-links:hover {
    color: ${props => props.theme["secondaryColor"]};
  }

  .link-header {
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 15px;
      font-weight: bold;
    }
  }

  .link-header-border {
    box-sizing: border-box;
    border: 1px solid ${props => props.theme["secondaryColor"]};
  }

  .head-to-head {
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .bristol {
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
  }

  .sm-logo {
    width: 45px;
    padding: 15px 10px;
  }
`

const Footer = () => {
  return (
    <StyledWrapper>
      <div className="footer container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-8">
            <small className="text-light">© 2022 upReach Charitable Company | Charity Number 1158896 | Company Number 08300398</small>
          </div>
          <div className="col-12 col-lg-4">
            <div className="row d-flex text-right justify-content-end">
              <Link target="_blank" to="https://twitter.com/up_Reach">
                <img className="sm-logo" src={tweet} alt="" />
              </Link>
              <Link target="_blank" to="https://www.facebook.com/upReachUK/">
                <img className="sm-logo" src={fb} alt="" />
              </Link>
              <Link target="_blank" to="https://www.linkedin.com/company/upreach/">
                <img className="sm-logo" src={ln} alt="" />
              </Link>
              <Link target="_blank" to="https://www.instagram.com/upreach_team/">
                <img className="sm-logo" src={ig} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default Footer
