/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import {ThemeProvider} from "styled-components";
import theme from '../theme';
import GlobalStyle from '../globalStyle';
import {Helmet} from "react-helmet";
import Header from "./header"
import "./layout.css"
import Footer from "./footer";
import MobileHeader from "./MobileHeader";
import SEO from "./seo";
import logoImage from "../images/smn_logo.png"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap" rel="stylesheet"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap"
                      rel="stylesheet"/>
                <SEO title="Social Mobility Network by upReach"/>
                <SEO description="A network for those without a network."/>
                <meta property="og:image" content={logoImage} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-P2T3BBFPC3"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-P2T3BBFPC3');`}
                </script>
                <meta name="robots" content="noindex" />
            </Helmet>
            <GlobalStyle/>
            <Header siteTitle="Social Mobility Network by upReach"/>
            <MobileHeader/>
            <div>
                <main className="fixFooter">{children}</main>
            </div>
            <Footer/>
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
